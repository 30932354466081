import React, { useEffect, useRef, useState } from "react";
import FotterAll from "../../Components/Footer/FotterAll";
import { Link } from "react-router-dom/cjs/react-router-dom";
import MainNavbar from "../../Components/Navbar/MainNavbar";
import BookingFailure from "../../Components/BookingStatus/BookingFailure";
import SuccessPage from "../../Components/BookingStatus/SuccessPage";

const BookingStatus = () => {
  const [showTick, setShowTick] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [bookingStatus, setBookingStatus] = useState(false);
  const [bookingId, setBookingId] = useState(false);
  const [bookingAmount, setBookingAmount] = useState(false);

  //https://testing.m2mferries.com/bookings/Backcontroller/FinalBookingStatus.php?status=true&bank_ref_no=eulgLGWSXAMHG34MADv&booking_id=14773383&amount=720

  //http://localhost:3000/booking-status/?status=true&bank_ref_no=eulgLGWSXAMHG34MADv&booking_id=45363448&amount=720

  useEffect(() => {
    //let show_nav = (new URLSearchParams(window.location.search)).get("show_nav")
    const params = new URLSearchParams(window.location.search);
    const booking_id = params.get('booking_id');
    const booking_status = params.get('status');
    const booking_amount = params.get('amount');

    setBookingId(booking_id);
    setBookingStatus(booking_status);
    setBookingAmount(booking_amount);
  //const foo = params.get('show-nav');
    console.log(booking_id, booking_status, booking_amount);

  }, []);

  
  //   our parents

  return (
    <div>
      <div className="ThankYouPageNavbar_Sticky">
          <MainNavbar isHomepage={false} />
      </div>
      <div className="ThankYouMainContainer">
      {bookingStatus === "true" ? (
        <>
        <div className="ThankYou_HomeHeading" style={{ cursor: "pointer" }}>
          <Link to={"/"}>
            <h1>Back To Home</h1>
          </Link>
        </div>
        {/* round checkbox start */}
        
          <SuccessPage booking_id={bookingId} booking_amount={bookingAmount} />
          </>
        ):(
          <BookingFailure booking_id={bookingId} />
        )}
        
      </div>
      <div>
        <FotterAll />
      </div>
    </div>
  );
};

export default BookingStatus;
