import React, { useEffect, useState } from "react";
import moment from "moment";
import { tr } from "date-fns/locale";
import KeyInformationMobile from "./KeyInformationMobile";
import Lottie from "lottie-react";
import animationData from "../../../animation.json";

const BookingSummary = ({}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    // Initial check on mount
    handleResize();

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array to ensure the effect runs only on mount and unmount

  const [storedData, setStoredData] = useState(null);
  const [tripTotalPrice, setTripTotalPrice] = useState(0);
  const nameMap = { MUM: "Mumbai", MWA: "Mandwa" };

  useEffect(() => {
    const dataFromLocalStorage = localStorage.getItem("formData");
    const storedData = JSON.parse(dataFromLocalStorage);
    setStoredData(storedData);
    var onwardPassengerTotal = 0;
    var onwardVehicleTotal = 0;
    var onwardTotalPrice = 0;
    var returnPassengerTotal = 0;
    var returnVehicleTotal = 0;
    var returnTotalPrice = 0;
    var tripTotalPrice = 0;
    // console.log(storedData);
    if (
      storedData &&
      storedData.onwardJourney &&
      storedData.onwardJourney.passengersData
    ) {
      const { onwardJourney } = storedData;
      const { passengersData, SelTripData } = onwardJourney;
      const { selectedVehicles, petinfo, available, cruiseCatAvailability } = SelTripData;
      const { price_detail } = cruiseCatAvailability;

      if (passengersData) {
        var Adultcount = passengersData.Adultcount;
        var Childcount = passengersData.Childcount;
        var Petcount = passengersData.Petcount;
        
        var adultPrice = Adultcount * available.single_room_price;
        var childPrice = Childcount * price_detail.additional_infant;
        if(Petcount > 0){
          var petPrice = Petcount * petinfo.price;
        }
        else{
          var petPrice = Petcount * 0;
        }
        onwardPassengerTotal = adultPrice + childPrice + petPrice;

        if (selectedVehicles) {
          selectedVehicles.map((item) => {
            onwardVehicleTotal += Number(item.price * item.quantity);
          });
        }
      }
      //console.log(onwardVehicleTotal);
      onwardTotalPrice = onwardPassengerTotal + onwardVehicleTotal;
    }
    // console.log(onwardTotalPrice);
    if (
      storedData &&
      storedData.returnJourney &&
      storedData.returnJourney.passengersData
    ) {
      const { returnJourney } = storedData;
      const { passengersData, SelTripData } = returnJourney;
      const { selectedVehicles, petinfo, available, cruiseCatAvailability } = SelTripData;
      const { price_detail } = cruiseCatAvailability;

      if (passengersData) {
        var Adultcount = passengersData.Adultcount;
        var Childcount = passengersData.Childcount;
        var Petcount = passengersData.Petcount;
        var adultPrice = Adultcount * available.single_room_price;
        var childPrice = Childcount * price_detail.additional_infant;
        var petPrice = Petcount * petinfo.price;
        returnPassengerTotal = adultPrice + childPrice + petPrice;

        if (selectedVehicles) {
          selectedVehicles.map((item) => {
            returnVehicleTotal += Number(item.price * item.quantity);
          });
        }
      }
      returnTotalPrice = returnPassengerTotal + returnVehicleTotal;
    }

    tripTotalPrice = onwardTotalPrice + returnTotalPrice;
    setTripTotalPrice(parseFloat(tripTotalPrice));
    
  }, [tripTotalPrice]);
  if (!storedData) {
    return (
      <div>
        {" "}
        <Lottie
          animationData={animationData}
          style={{ width: "200px", height: "200px", margin: "auto" }}
        />
      </div>
    );
  }

  return (
    <div className="BookingSummary" style={{ width: "100%" }}>
      {/* summary section */}
      <div className="confirmation_booking_summary">
        <div className="Deoparture_Summary">
          <div className="bookingSummary_heading">
            <h2>Booking Summary</h2>
            <h3>
              {storedData.triptype === "return" ? "Round-Trip" : "One-Way"}
            </h3>
          </div>

          <div className="departure_basisc_information">
            <div>
             <h2>Departure</h2>
              <p>
                {nameMap[storedData.onwardJourney.SelTripData.start_port]} To{" "}
                {nameMap[storedData.onwardJourney.SelTripData.end_port]}
              </p>
            </div>
            <div>
              <h2>Vessel</h2>
              <p>{storedData.onwardJourney.SelTripData.cruise_name}</p>
            </div>
          </div>
         
         <div className="besicinformation_content-desk">
          <div className="besicinformation_content">
            <div className="besicinformation_contentDiv1">
              <p>Departure Date</p>
              <h2>{moment(storedData.onwardJourney.SelTripData.sailing_date, 'MM/DD/YYYY').format("DD MMMM YYYY")}</h2>
            </div>
            <div className="besicinformation_contentDiv1">
              <p>Departure Time</p>
              <h2>{moment(storedData.onwardJourney.SelTripData.sailing_time, ["H:mm:ss",]).format("LT")}{" "}</h2>
            </div>
            <div className="besicinformation_contentDiv1">
              <p>Arrival Time</p>
                <h2>{moment(storedData.onwardJourney.SelTripData.sailing_time, ["H:mm:ss"]).add(1, 'hours').format("LT")}</h2>
            </div>
            {/* <div className="besicinformation_contentDiv1">
              <p>No. Of Stops</p>
              <h2>One Stop</h2>
            </div> */}
          </div>
         </div>

          <div className="besicinformation_content-mob">
            <div className="besicinformation_contentDiv1-mob">
              <p>Departure Date</p>
              <h2>{moment(storedData.onwardJourney.SelTripData.sailing_date, 'MM/DD/YYYY').format("DD MMMM YYYY")}</h2>
            </div>
            <div className="dep-ret-timeRow">
              <div className="besicinformation_contentDiv1-mob dep-ret-timeRow-subdiv1">
                <p>Departure Time </p>
                <h2>{moment(storedData.onwardJourney.SelTripData.sailing_time, ["H:mm:ss",]).format("LT")}{" "}</h2>
              </div>
              <div className="besicinformation_contentDiv1-mob dep-ret-timeRow-subdiv2">
                <p>Arrival Time</p>
                <h2>{moment(storedData.onwardJourney.SelTripData.sailing_time, ["H:mm:ss"]).add(1, 'hours').format("LT")}</h2>
              </div>
            </div>
            {/* <div className="besicinformation_contentDiv1-mob">
              <p>No. Of Stops</p>
              <h2>One Stop</h2>
            </div> */}
          </div>

          <div className="departure_seating_Category">
            <p>Seating Category</p>
            <h2>{storedData.onwardJourney.SelTripData.available.category_name}</h2>
          </div>

          {/* seating area */}
          {/* <div className="Seating_area_Main">
                <h1>Seating Area</h1>
                <div className="Seating_area_Parent">
                  <div className="Seating_area_Main_div1">
                    <p>Passengers</p>
                    <h2>Akash Kamble</h2>
                    <h2>Shweta Kamble</h2>
                  </div>
                  <div className="Seating_area_Main_div2">
                    <p>Seat Number</p>
                    <h2>A1</h2>
                    <h2>D1</h2>
                  </div>
                </div>
              </div> */}

          {/* passanger Details */}
          <div className="passanger_summary_Main">
            <h2>Passenger Details</h2>
            <div className="PassangerDtailed_Heading_New">
              <div>
                <h1 id="pass_left">Description</h1>
                <h1 id="Qun_center">Quantity</h1>
                <h1 id="price_left">Rate</h1>
                <h1 id="total_left">Amount</h1>
              </div>
              <div className="Content_PassangerDetail_New" id="BorderBottamConfirmation">
                <p id="pass_left">Passengers</p>
                <p id="Qun_center">{storedData.onwardJourney.passengersData.Adultcount}</p>
                <p id="price_left">₹{" "}{storedData.onwardJourney.SelTripData.available.single_room_price}</p>
                <p id="total_left">
                  ₹{(storedData.onwardJourney.SelTripData.available.single_room_price *storedData.onwardJourney.passengersData.Adultcount).toFixed(2)}
                </p>
              </div>
              {storedData.onwardJourney.passengersData.Childcount > 0 && (
                <div className="Content_PassangerDetail_New"  id="BorderBottamConfirmation">
                  <p id="pass_left">Infants</p>
                  <p id="Qun_center">{storedData.onwardJourney.passengersData.Childcount}</p>
                  <p id="price_left">₹{" "}{storedData.onwardJourney.SelTripData.cruiseCatAvailability.price_detail.additional_infant}</p>
                  <p id="total_left">₹ {(storedData.onwardJourney.SelTripData.cruiseCatAvailability.price_detail.additional_infant *storedData.onwardJourney.passengersData.Childcount).toFixed(2)}</p>
                </div>
              )}
              {storedData.onwardJourney.passengersData.Petcount > 0 && (
                <div className="Content_PassangerDetail_New"  id="BorderBottamConfirmation">
                  <p id="pass_left">Pets</p>
                  <p id="Qun_center">{storedData.onwardJourney.passengersData.Petcount}</p>
                  <p id="price_left">₹ {storedData.onwardJourney.SelTripData.petinfo.price}</p>
                  <p id="total_left">₹{(storedData.onwardJourney.SelTripData.petinfo.price *storedData.onwardJourney.passengersData.Petcount).toFixed(2)}{" "}
                  </p>
                </div>
              )}
            </div>
          </div>
          {/* vechicle Details */}
          <div className={ storedData.onwardJourney.SelTripData.selectedVehicles && storedData.onwardJourney.SelTripData.selectedVehicles.length > 0 ? "passanger_summary_Main" : "noVechicleClassNone"}>
            <h2>Vechicle Details</h2>
            <div className="PassangerDtailed_Heading_New">
              <div>
                <h1 id="pass_left">Description</h1>
                <h1 id="Qun_center">Quantity</h1>
                <h1 id="price_left">Rate</h1>
                <h1 id="total_left">Amount</h1>
              </div>
              {storedData.onwardJourney.SelTripData.selectedVehicles && storedData.onwardJourney.SelTripData.selectedVehicles.length >0 &&
                storedData.onwardJourney.SelTripData.selectedVehicles.map(
                  (vehicle, indexvehicle) => {
                    return (
                      <div className="Content_PassangerDetail_New" key={indexvehicle} id="BorderBottamConfirmation">
                        <p id="pass_left">{vehicle.excursion_name}</p>
                        <p id="Qun_center"> {vehicle.quantity}</p>
                        <p id="price_left">₹ {vehicle.price}</p>
                        <p id="total_left">
                          ₹ {(vehicle.quantity * vehicle.price).toFixed(2)}
                        </p>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        </div>
        {/* Return Duplicate of departure */}
        {storedData.triptype === "return" && (
          <div className="Return_summary">
            <div className="departure_basisc_information">
            <div>
             <h2>Return</h2>
              <p>{nameMap[storedData.returnJourney.SelTripData.start_port]} To {nameMap[storedData.returnJourney.SelTripData.end_port]}</p>
            </div>
            <div>
              <h2>Vessel</h2>
              <p>{storedData.returnJourney.SelTripData.cruise_name}</p>
            </div>
            </div>

            <div className="besicinformation_content-desk">
              <div className="besicinformation_content">
                <div className="besicinformation_contentDiv1">
                  <p>Return Date</p>
                  <h2>{moment(storedData.returnJourney.SelTripData.sailing_date, 'MM/DD/YYYY').format("DD MMMM YYYY")}</h2>
                </div>
                {/* <div className="besicinformation_contentDiv1">
                  <p>Return Time</p>
                  <h2>{moment(storedData.returnJourney.SelTripData.sailing_time, ["H:mm:ss",], 'MM/DD/YYYY').format("LT")}{" "}to{" "}{moment(storedData.returnJourney.SelTripData.sailing_time, ["H:mm:ss"]).add(1, 'hours').format("LT")}</h2>
                </div> */}
                <div className="besicinformation_contentDiv1">
                  <p>Return Time</p>
                  <h2>{moment(storedData.returnJourney.SelTripData.sailing_time, ["H:mm:ss",]).format("LT")}{" "}</h2>
                </div>
                <div className="besicinformation_contentDiv1">
                  <p>Arrival Time</p>
                    <h2>{moment(storedData.returnJourney.SelTripData.sailing_time, ["H:mm:ss"]).add(1, 'hours').format("LT")}</h2>
                </div>
                {/* <div className="besicinformation_contentDiv1">
                  <p>No. Of Stops</p>
                  <h2>One Stop</h2>
                </div> */}
              </div>
            </div>

           {/* for mobile */}
            <div className="besicinformation_content-mob">
              <div className="besicinformation_contentDiv1-mob">
                <p>Return Date</p>
                <h2>{moment(storedData.returnJourney.SelTripData.sailing_date, 'MM/DD/YYYY').format("DD MMMM YYYY")}</h2>
               </div>
              <div className="dep-ret-timeRow">
                <div className="besicinformation_contentDiv1-mob dep-ret-timeRow-subdiv1">
                  <p>Return Time</p>
                  <h2>{moment(storedData.returnJourney.SelTripData.sailing_time, ["H:mm:ss",], 'MM/DD/YYYY').format("LT")}</h2>
                </div>
                <div className="besicinformation_contentDiv1-mob dep-ret-timeRow-subdiv2">
                  <p>Arrival Time</p>
                  <h2>{moment(storedData.returnJourney.SelTripData.sailing_time, ["H:mm:ss"]).add(1, 'hours').format("LT")}</h2>
                  </div>
              </div>
              {/* <div className="besicinformation_contentDiv1-mob">
                <p>No. Of Stops</p>
                <h2>One Stop</h2>
              </div> */}
            </div>

            <div className="departure_seating_Category">
              <p>Seating Category</p>
              <h2>
                {storedData.returnJourney.SelTripData.available.category_name}
              </h2>
            </div>

            {/* seating area */}
            {/* <div className="Seating_area_Main">
              <h1>Seating Area</h1>
              <div className="Seating_area_Parent">
                <div className="Seating_area_Main_div1">
                  <p>Passengers</p>
                  <h2>Akash Kamble</h2>
                  <h2>Shweta Kamble</h2>
                </div>
                <div className="Seating_area_Main_div2">
                  <p>Seat Number</p>
                  <h2>A1</h2>
                  <h2>D1</h2>
                </div>
              </div>
            </div> */}

            {/* passanger Details */}
            <div className="passanger_summary_Main">
              <h2>Passenger Details</h2>
              <div className="PassangerDtailed_Heading_New" >
                <div>
                  <h1 id="pass_left">Description</h1>
                  <h1 id="Qun_center">Quantity</h1>
                  <h1 id="price_left">Rate</h1>
                  <h1 id="total_left">Amount</h1>
                </div>
                <div className="Content_PassangerDetail_New"  id="BorderBottamConfirmation">
                  <p id="pass_left">Passengers</p>
                  <p id="Qun_center">
                    {" "}
                    {storedData.returnJourney.passengersData.Adultcount}
                  </p>
                  <p id="price_left">
                    ₹{" "}
                    {storedData.returnJourney.SelTripData.available.single_room_price}{" "}
                  </p>
                  <p id="total_left">
                    ₹{" "}
                    {(
                      storedData.returnJourney.SelTripData.available
                        .single_room_price *
                      storedData.returnJourney.passengersData.Adultcount
                    ).toFixed(2)}
                  </p>
                </div>
                {storedData.returnJourney.passengersData.Childcount > 0 && (
                  <div className="Content_PassangerDetail_New"  id="BorderBottamConfirmation">
                    <p id="pass_left">Infants</p>
                    <p id="Qun_center">
                      {" "}
                      {storedData.returnJourney.passengersData.Childcount}
                    </p>
                    <p id="price_left">₹{" "}
                  {
                    storedData.returnJourney.SelTripData.cruiseCatAvailability.price_detail.additional_infant
                  }</p>
                  <p id="total_left">₹ {(
                    storedData.returnJourney.SelTripData.cruiseCatAvailability.price_detail.additional_infant *
                    storedData.returnJourney.passengersData.Childcount
                  ).toFixed(2)}</p>
                  </div>
                )}
                {storedData.returnJourney.passengersData.Petcount > 0 && (
                  <div className="Content_PassangerDetail_New"  id="BorderBottamConfirmation">
                    <p id="pass_left">Pets</p>
                    <p id="Qun_center">
                      {" "}
                      {storedData.returnJourney.passengersData.Petcount}
                    </p>
                    <p id="price_left">
                      {" "}
                      ₹ {storedData.returnJourney.SelTripData.petinfo.price}
                    </p>
                    <p id="total_left">
                      ₹{" "}
                      {(
                        storedData.returnJourney.SelTripData.petinfo.price *
                        storedData.returnJourney.passengersData.Petcount
                      ).toFixed(2)}{" "}
                    </p>
                  </div>
                )}
              </div>
            </div>
            {/* vechicle Details */}
            <div
              className={ storedData.returnJourney.SelTripData.selectedVehicles && storedData.returnJourney.SelTripData.selectedVehicles.length > 0
                  ? "passanger_summary_Main"
                  : "noVechicleClassNone"
              }>
              <h2>Vechicle Details</h2>
              <div className="PassangerDtailed_Heading_New">
                <div>
                  <h1 id="pass_left">Description</h1>
                  <h1 id="Qun_center">Quantity</h1>
                  <h1 id="price_left">Rate</h1>
                  <h1 id="total_left">Amount</h1>
                </div>
                {storedData.returnJourney.SelTripData.selectedVehicles &&
                  storedData.returnJourney.SelTripData.selectedVehicles.length >
                    0 &&
                  storedData.returnJourney.SelTripData.selectedVehicles.map(
                    (vehicle, indexvehicle) => {
                      return (
                        <div
                          className="Content_PassangerDetail_New"
                          key={indexvehicle}
                          id="BorderBottamConfirmation"
                        >
                          <p id="pass_left">{vehicle.excursion_name}</p>
                          <p id="Qun_center"> {vehicle.quantity}</p>
                          <p id="price_left">₹ {vehicle.price}</p>
                          <p id="total_left">
                            ₹ {(vehicle.quantity * vehicle.price).toFixed(2)}
                          </p>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
          </div>
        )}

        {/* for mobile Add ones section */}
        <div className="passanger_summary_Main_AddOne">
          <h1>Add Ons</h1>
          <div className="Passenger_Details_parent">
            <div className="Passenger_Details_parent_Div1">
              <p>Description</p>
              <h2>Table Reservation</h2>
              <h2>Modification Charge</h2>
            </div>
            <div className="Passenger_Details_parent_Div2">
              <p>Quantity</p>
              <h2>1</h2>
              <h2>1</h2>
            </div>
            <div className="Passenger_Details_parent_Div3">
              <p>Rate</p>
              <h2>₹ 100.00</h2>
              <h2>₹ 100.00</h2>
            </div>
            <div className="Passenger_Details_parent_Div4">
              <p>Amount</p>
              <h2>₹ 100.00</h2>
              <h2>₹ 100.00</h2>
            </div>
          </div>
        </div>
        {/* apply Coupon */}
        {/* <div className="ApplyCoupon_return">
              <div className="ApplyCoupon_return_Div1">
                <div>
                  <h1>Apply Coupon</h1>
                </div>
                <div>
                  <p>View All Offers</p>
                </div>
              </div>
              <div className="ApplyCoupon_return_Div2">
                <input type="text" />
              </div>
              <div className="ApplyCoupon_returnDiv3">
                <button
                  id="color-fill-button"
                  className="applybuton"
                  type="button"
                  style={{
                    border: "1px solid black",
                    marginRight: "-15px",
                  }}
                >
                  Apply
                </button>
              </div>
            </div> */}
        {/* Grand Total */}

        <div className="GrandTotal_summary">
          <div>
            <h2>Grand Total </h2>
            <p className="GrandTotal_summary_span">*GST not applicable</p>
          </div>
          <h3>₹ {tripTotalPrice ? tripTotalPrice.toFixed(2) : 0}</h3>
        </div>
      </div>
      <div className="keyMobileCompoentnt">
        {isMobile ? <KeyInformationMobile /> : ""}
      </div>
    </div>
  );
};

export default BookingSummary;
