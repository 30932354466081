import "./FullCalender.css";
import { useState, useEffect } from "react";
import Calendar, { CalendarDayHeader } from "./Calendar";
import TripDataService from "../../services/TripData";
import moment from "moment";
import EventModal from "./EventModal.jsx";


const FullCalendar = () => {
  const [calenderEvents, setcalenderEvents] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    var rawData = JSON.stringify({
      year: moment().format("Y"),
      live_data: true,
    });
    TripDataService.fetchTrips(rawData)
      .then((response) => {
        if (response.status !== "error") {
          setAvailableDates(response.sailings); // Trips data for a year 
          const uniqueTags1 = [];
          response.sailings.map(availableDate => {
            if (uniqueTags1.indexOf(availableDate.sailing_date) === -1) {
              uniqueTags1.push(availableDate.sailing_date)
            }
          });
          
          const eventsobj = [];
          uniqueTags1.map(availableDate => {
            var obj = {
              title: "",
              date: moment(availableDate, 'MM/DD/YYYY').format("YYYY-MM-DD"),
              className: "M2M-1-Event",
            }
            // var obj1 = {
            //   title: "Princess",
            //   date: moment(availableDate).format("YYYY-MM-DD"),
            //   className: "PrincessEvent",
            // }
          
            // eventsobj.push(obj, obj1);
            //eventsobj.push(obj);
            eventsobj.push(moment(availableDate, 'MM/DD/YYYY').format("YYYY-MM-DD"));

          })
          
          setcalenderEvents(eventsobj);
        }
        else{
          console.log("Error");
        }
      })
      .catch((err) => {
        /* setError(err.message);
        setLoading(false); */
      });
      
  }, []);

  const handleDateClick = (date) => {
    var DataToStore = {
      selectedEvent: moment(date.$d).format('MM/D/YYYY'),
      availableDates: availableDates
    };
    
    localStorage.setItem("scheduleSelected", JSON.stringify(DataToStore));
    setSelectedEvent({ date: date });
    setModalOpen(true);
    // Perform any action you want with the clicked date
  };
  
  const handleMobileDateClick = (date) => {
    
    var DataToStore = {
      selectedEvent: moment(date.$d).format('MM/D/YYYY'),
      availableDates: availableDates
    };
    
    localStorage.setItem("scheduleSelected", JSON.stringify(DataToStore));
    window.location = "/mobile-schedule-event";
  };
  const closeModal = () => {
    setSelectedEvent(null);
    setModalOpen(false);
  };


  return (
    <div className="App calendar-wrapper">
      {/* <Calendar
        yearAndMonth={yearAndMonth}
        onYearAndMonthChange={setYearAndMonth}
        renderDay={(calendarDayObject) => (
          <div className="renderday">
            <CalendarDayHeader calendarDayObject={calendarDayObject} />
          </div>
        )}
      /> */}
      <div className="Calendar_Desktop">
        <Calendar  highlightedDates={calenderEvents}  onDateClick={handleDateClick}  />
      </div>
      <div className="Calendar_Mobile">
        <Calendar  highlightedDates={calenderEvents}  onDateClick={handleMobileDateClick}  />
      </div>
      {isModalOpen && (
        <EventModal selectedEvent={selectedEvent} availableDates={availableDates} closeModal={closeModal} />
      )}
    </div>
  );
};

export default FullCalendar;
