import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "../Pages/Home/HomePage";
import DepartureM2M from "../Pages/Onword/DepartureM2M";
import ReturnM2M from "../Pages/Return/ReturnM2M";
import Calendar from "../Pages/Schedule/Schedule";
import Vehicle from "../Pages/Vehicle/Vehicle";
import AboutUS from "../Pages/About_Us/AboutUS";
import ConfirmationPage from "../Pages/Confirmation/ConfirmationPage";
import ThankYouPage from "../Pages/ThankYouPage/ThankYouPage";
import AccountPage from "../Pages/Account/AccountPage";
import SeatingArea from "../Pages/Seating_Area/SeatingArea";
import Signup from "../Pages/SignUp/Signup";
import Login from "../Pages/Login/Login";
import BookingHistory from "../Pages/BookingHistory/BookingHistory";
import Notification from "../Pages/NotificationPAge/Notification";
import ContactForm from "../Pages/ContactForm/ContactForm";
import FaqPage from "../Pages/FAQ/FaqPage";
import ErrorPage from "../Pages/ErrorPage/ErrorPage";
import BookingStatus from "../Pages/BookingStatus/BookingStatus";
import Onward_M2M_Page from "../Pages/Onward_Experience_Pages/Onward_M2M_Page";
import EventsPage from "../Pages/Onward_Experience_Pages/EventsPage";
import Onboard_M2M_Princess from "../Pages/Onward_Experience_Pages/Onboard_M2M_Princess";
import TermsAndCondition from "../Pages/TermsAndCondition/TermsAndCondition";
import Travel_Guide from "../Pages/Travel_Guide/Travel_Guide";
import EventModalMobile from "../Pages/Schedule/EventModalMobile";

const AllRoutes = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/onward-departure" component={DepartureM2M} />
        <Route path="/onward-return" component={ReturnM2M} />
        <Route path="/vehicle" component={Vehicle} />
        <Route path="/onward-seats-selection" component={SeatingArea} />
        <Route path="/schedule" component={Calendar} />
        <Route path="/mobile-schedule-event" component={EventModalMobile} />
        <Route path="/about-us" component={AboutUS} />
        <Route path="/confirmation" component={ConfirmationPage} />
        <Route path="/confirm" component={ThankYouPage} />
        <Route path="/contact" component={ContactForm} />
        <Route path="/faq" component={FaqPage} />
        <Route path="/personal-information" component={AccountPage} />
        <Route path="/signup" component={Signup} />

        <Route path="/login" component={Login} />

        <Route path="/booking-history" component={BookingHistory} />
        <Route path="/notifications" component={Notification} />
        <Route path="/error-page" component={ErrorPage} />
        <Route path="/booking-status" component={BookingStatus} />
        <Route path="/m2m-1" component={Onward_M2M_Page} />
        <Route path="/princess" component={Onboard_M2M_Princess} />

        <Route path="/events" component={EventsPage} />
        <Route path="/terms-conditions" component={TermsAndCondition} />
        <Route path="/travel-guide" component={Travel_Guide} />

      </Switch>
    </div>
  );
};

export default AllRoutes;
