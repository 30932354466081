import React, { useEffect, useState, useRef } from 'react';
import './EventsPage.css';
import MainNavbar from '../../Components/Navbar/MainNavbar';
import FotterAll from '../../Components/Footer/FotterAll';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useLocation } from 'react-router-dom';

const tabImageMappings = {
  0: 'Seating Areas',
  1: 'Dining',
  2: 'Vehicle Deck',
  3: 'Pet Zone',
  4: 'Events',
  5: 'Oceanic Views',
};

const EventsPage = () => {
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialTab = parseInt(queryParams.get('tab')) || 0;
  const [activeTab, setActiveTab] = useState(initialTab);

  // const [isMobileSlider, setIsMobileSlider] = useState(false);

  const horizontalSliderRef = useRef(null);
  const verticalSliderRef = useRef(null);

  // useEffect(() => {
  //   const handleResizer = () => {
  //     setIsMobileSlider(window.innerWidth <= 768);
  //   };
  //   handleResizer();
  //   window.addEventListener('resize', handleResizer);
  //   return () => window.removeEventListener('resize', handleResizer);
  // }, []);

  const handleTabsClick = (index) => {
    setActiveTab(index);
  };

  const HorizontalSlides = [
    [
      { image: '/assets/img/Gallery1/Seating_Areas/Skoda_Kushaq_Zone/new_seat_area1.jpg', text: 'Škoda Kushaq Zone' },
      { image: '/assets/img/Gallery1/Seating_Areas/Skoda_Kushaq_Zone/new_seat_area2.jpg', text: 'Škoda Kushaq Zone' },
      { image: '/assets/img/Gallery1/Seating_Areas/Skoda_Kushaq_Zone/new_seat_area3.jpg', text: 'Škoda Kushaq Zone' },
      { image: '/assets/img/Gallery1/Seating_Areas/Skoda_Slavia_Deck/new_seat_area1.jpg', text: 'Škoda Slavia Deck' },
      { image: '/assets/img/Gallery1/Seating_Areas/Skoda_Slavia_Deck/new_seat_area2.jpg', text: 'Škoda Slavia Deck' },
      // { image: '/assets/img/Gallery1/Seating_Areas/Skoda_Slavia_Deck/new_seat_area3.jpg', text: 'Škoda Slavia Deck' },
      { image: '/assets/img/Gallery1/Seating_Areas/Skoda_Superb_lounge/new_seat_area1.jpg', text: 'Škoda Superb Lounge ' },
      { image: '/assets/img/Gallery1/Seating_Areas/Skoda_Superb_lounge/new_seat_area2.jpg', text: 'Škoda Superb Lounge ' },
      { image: '/assets/img/Gallery1/Seating_Areas/Skoda_Superb_lounge/new_seat_area3.jpg', text: 'Škoda Superb Lounge ' },
    ],
    [
      { image: 'assets/img/Gallery1/Dining/Meemo_s_Cafe/new_dining2.jpg', text: 'Meemo’s Café' },
      { image: '/assets/img/Gallery1/Dining/Starboard/new_Starboard_Bar1.jpg', text: 'Starboard Bar' },
    ],
    [
      { image: '/assets/img/Gallery1/Vehicle_Deck/new_up_vechicle_deck1.jpg', text: 'Vehicle Deck' },
      { image: '/assets/img/Gallery1/Vehicle_Deck/new_up_vechicle_deck2.jpg', text: 'Vehicle Deck' },
      { image: '/assets/img/Gallery1/Vehicle_Deck/new_up_vechicle_deck3.jpg', text: 'Vehicle Deck' },
    ],
    [
      { image: '/assets/img/Gallery1/Pet_Zone/new_up_petzone1.jpg', text: 'Pet Zone' },
      { image: '/assets/img/Gallery1/Pet_Zone/new_up_petzone2.jpg', text: 'Pet Zone' },
      { image: '/assets/img/Gallery1/Pet_Zone/new_up_petzone3.jpg', text: 'Pet Zone' },
      { image: '/assets/img/Gallery1/Pet_Zone/new_up_petzone4.jpg', text: 'Pet Zone' },
    ],
    [
      { image: '/assets/img/Gallery1/Events/Corporate_Outing/new_events_co1.jpg', text: 'Corporate Outing' },
      { image: '/assets/img/Gallery1/Events/Corporate_Outing/new_events_co2.jpg', text: 'Corporate Outing' },
      { image: '/assets/img/Gallery1/Events/Corporate_Outing/new_events_co3.jpg', text: 'Corporate Outing' },
      // { image: '/assets/img/Gallery1/Events/School_Field_Trip/new_events_school1.jpg', text: 'School Field Trip' },
      { image: '/assets/img/Gallery1/Events/School_Field_Trip/new_events_school2.jpg', text: 'School Field Trip' },
      { image: '/assets/img/Gallery1/Events/School_Field_Trip/new_events_school3.jpg', text: 'School Field Trip' },
      { image: '/assets/img/Gallery1/Events/School_Field_Trip/new_xyz_school1.jpg', text: 'Youth Group Outings' },
      { image: '/assets/img/Gallery1/Events/School_Field_Trip/new_xyz_school2.jpg', text: 'Youth Group Outings' },
      
      
      { image: '/assets/img/Gallery1/Events/Sunburn/new_events_sunburn1.jpg', text: 'Sunburn Music Festival' },
      { image: '/assets/img/Gallery1/Events/Sunburn/new_events_sunburn2.jpg', text: 'Sunburn Music Festival' },
      { image: '/assets/img/Gallery1/Events/Sunburn/new_events_sunburn3.jpg', text: 'Sunburn Music Festival' },
      { image: '/assets/img/Gallery1/Events/Wedding/new_events_wedding1.jpg', text: 'Wedding' },
      { image: '/assets/img/Gallery1/Events/Wedding/new_events_wedding2.jpg', text: 'Wedding' },
    ],
    [
      { image: '/assets/img/Gallery1/Oceanic_Views/new_oceanic_view1.jpg', text: 'Oceanic View' },
      { image: '/assets/img/Gallery1/Oceanic_Views/new_oceanic_view2.jpg', text: 'Oceanic View' },
      { image: '/assets/img/Gallery1/Oceanic_Views/new_oceanic_view3.jpg', text: 'Oceanic View' },
    ],
  ];

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: nav2,
  };

  const settingsThumbs = {
    slidesToShow: 4.2,
    slidesToScroll: 1,
    asNavFor: nav1,
    focusOnSelect: true,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
  };
  const settingsThumbsmobile = {
    slidesToShow: 2.5,
    slidesToScroll: 1,
    asNavFor: nav1,
    focusOnSelect: true,
    vertical: false,
    verticalSwiping: false,
    arrows: false,
    infinite: false,
  };


  return (
    <div>
      <div className="navbarContainer-sticky Main-navbar-border">
        <MainNavbar />
      </div>
      <div className="Events_Section">
        <div className="Events_Section_Heading">
          <h1>Gallery</h1>
        </div>
        <div className="Events_WhiteLinearGradint"></div>
      </div>

      <div className="EventsMain_Continer">
        <div className="tabbed-interface">
          <div className="tab-headers">
            {Object.values(tabImageMappings).map((name, index) => (
              <div key={index} className={`tab-header ${activeTab === index ? 'active' : ''}`} onClick={() => handleTabsClick(index)}>
                <p>{name}</p>
              </div>
            ))}
          </div>
          <div className="tab_content1">
            <div className="slider-wrapper">
              <div className="main-slider">
                <Slider {...settingsMain} ref={setNav1}>
                  {HorizontalSlides[activeTab].map((image, index) => (
                    <div key={index} className="slick_slide_main">
                      <img src={image.image} alt={image.text} className="main-image" />
                      <div className="slide_content_horizontal">
                        <p>{image.text}</p>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="thumbnail-slider-wrapper">
                <Slider {...settingsThumbs} ref={setNav2}>
                  {HorizontalSlides[activeTab].map((image, index) => (
                    <div key={index}>
                      <img src={image.image} alt={image.text} className="thumbnail-image" />
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="thumbnail-slider-wrapper-mobile">
                <Slider {...settingsThumbsmobile} ref={setNav2}>
                  {HorizontalSlides[activeTab].map((image, index) => (
                    <div key={index}>
                      <img src={image.image} alt={image.text} className="thumbnail-image-mobile" />
                    </div>
                  ))}
                </Slider>
              </div>

          
            </div>
          </div>
        </div>
      </div>

      <FotterAll />
    </div>
  );
};

export default EventsPage;

