import React, { useEffect, useState } from 'react'
import "./TermsAndCondition.css"
import FotterAll from '../../Components/Footer/FotterAll'
import MainNavbar from '../../Components/Navbar/MainNavbar'
import StaticDataService from "../../services/staticData";


const TermsAndCondition = () => {
  const [TemsData,setTermsData] = useState()
  useEffect(()=>{
    StaticDataService.StaticData()
    .then((response) => {
      setTermsData(response.data[0].description)
    })
    .catch((err) => {
      console.log(err,"error")
    });

  })
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="navbarContainer-sticky Main-navbar-border">
        <MainNavbar />
      </div>
      <div className='TermsAndcondition_Content'>
        <div className='TermsAndcondition_Content_main'>
            {/* <div className='TermsAndcondition_Heading'> 
               <h1>TERMS AND CONDITIONS FOR RO-PAX FERRY SERVICE <br /> FROM FERRY WHARF TO MANDWA</h1>
            </div>
            <div className='TermsAndcondition_SubText'> 
              <p>TERMS AND CONDITIONS FOR RO-PAX FERRY SERVICE FROM FERRY WHARF TO MANDWA</p>
              <p>FOLLOWING ARE THE TERMS AND CONDITIONS GOVERNING THE PROVISIONING OF RO-PAX FERRY AND TERMINAL RELATED SERVICES (“SERVICES”) BY M2M FERRIES PRIVATE LIMITED (“OPERATOR”) TO THE PERSON(S) ("USER / PASSENGER") WHO AGREE TO AVAIL THE SERVICES OF THE OPERATOR BY PURCHASING THE RO-PAX FERRY TICKET. BY PURCHASING THE TICKET OR MERCHANDISE, THE USER EXPRESSLY AGREES TO BE BOUND BY THESE TERMS AND CONDITIONS, INCLUDING THE ADDITIONAL TERMS AND CONDITIONS MENTIONED ON THE WEBSITE OF THE OPERATOR i.e. WWW.M2MFERRIES.COM, TERMS OF OUR PRIVACY POLICY, AND ALL APPLICABLE LAWS AND REGULATIONS GOVERNING THE USE OF SERVICES.</p>
              <ol>
                <li>TICKET IS VALID ONLY FOR THE SPECIFIC DATE & TRIP TIMING BOOKED. CHANGES/REFUND IF ANY ARE APPLICABLE AS PER THE RULES SPECIFIED</li>
                <li>ONLINE BOOKING CLOSES 45 MINUTES BEFORE SCHEDULED DEPARTURE SUBJECT TO SEAT AVAILABILITY.</li>
                <li>REPORTING TIME: 45 MINUTES PRIOR TO DEPARTURE. CHECK-IN AND GATES CLOSE 15 MIN PRIOR. PASSENGERS WITH CONFIRMED TICKETS WHO NOT ARRIVE BEFORE CHECK IN CLOSES ARE TREATED AS NO SHOW.</li>
                <li>PASSENGERS, WHO ARRIVE AT THE TERMINAL AFTER CHECK IN CLOSURE, WILL BE GIVEN THE OPTION TO BE REBOOKED ON ANOTHER TRIP, ON THE SAME DAY WITH A 50% SURCHARGE, SUBJECT TO AVAILABILITY ONLY. IF PASSENGER DOES NOT OPT FOR THIS OPTION OR IF SEATS ARE NOT AVAILABLE, NO REFUND IS APPLICABLE.</li>
              </ol>
            </div> */}
            {/* {TemsData.map((el,i)=>{
            })} */}
             <div className='TermsAndcondition_SubText'
                                  dangerouslySetInnerHTML={{
                                    __html:
                                    TemsData,
                                  }}
                                />

            <div className='TermsAndConditionButton_div'>
                <button className='TermsAndConditionButton'>Back</button>
            </div>
        </div>
      </div>

      <FotterAll />
    </div>
  )
}

export default TermsAndCondition