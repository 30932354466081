import React, { useEffect } from "react";
import "./SeatingArea.css";
import SliderImag from "../../Components/OnwordBooking_Comp/CommanComp/SliderImag";
import FotterAll from "../../Components/Footer/FotterAll";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";

const SeatingArea = () => {
  // localstorage access

  const FormData = JSON.parse(localStorage.getItem("formData")) || {};
  const onwardAdultCount =
    FormData.onwardJourney?.passengersData?.Adultcount || 0;

  const [columnA, setColumnA] = useState(Array(20).fill(0));
  const [columnB, setColumnB] = useState(Array(20).fill(0));
  const [columnC, setColumnC] = useState(Array(20).fill(0));
  const [columnD, setColumnD] = useState(Array(20).fill(0));
  const [columnE, setColumnE] = useState(Array(20).fill(0));
  const [columnF, setColumnF] = useState(Array(20).fill(0));
  const [column, setColumn] = useState(
    Array.from({ length: 20 }, (_, index) => String(index + 1).padStart(2, "0"))
  );

  const Prefilled = [
    "A-04",
    "B-05",
    "C-05",
    "B-14",
    "C-14",
    "B-16",
    "A-16",
    "D-05",
    "E-14",
    "F-14",
    "G-16",
    "D-16",
  ];

  // create and Two array seatArrray 1 and  2

  const SeatArray_1 = Array.from({ length: 20 }, (_, index) => {
    const seatNumber = String(index + 1).padStart(2, "0");
    return [`A-${seatNumber}`, `B-${seatNumber}`, `C-${seatNumber}`];
  }).flat();
  // console.log(SeatArray_1);

  const SeatArray_2 = Array.from({ length: 20 }, (_, index) => {
    const seatNumber = String(index + 1).padStart(2, "0");
    return [`D-${seatNumber}`, `E-${seatNumber}`, `F-${seatNumber}`];
  }).flat();
  // console.log(SeatArray_2);

  const [selectedSeats, setSelectedSeats] = useState(
    Array.from({ length: onwardAdultCount }, () => ({ row: null, seat: null }))
  );

  const [isSelectionComplete, setIsSelectionComplete] = useState(false);

  const [expandedPassengerIndex, setExpandedPassengerIndex] = useState(null);

  const handleBoxClick = (column, rowIndex) => {
    const seatKey = `${column}-${String(rowIndex + 1).padStart(2, "0")}`;

    if (!Prefilled.includes(seatKey)) {
      setSelectedSeats((prevSelectedSeats) => {
        const existingSeatIndex = prevSelectedSeats.findIndex(
          (seat) => seat.row === column && seat.seat === rowIndex + 1
        );

        // If the seat is already selected, deselect it
        if (existingSeatIndex !== -1) {
          const updatedSeats = [...prevSelectedSeats];
          updatedSeats[existingSeatIndex] = { row: null, seat: null };
          setIsSelectionComplete(false); // Reset the flag
          setExpandedPassengerIndex(null); // Collapse the expanded passenger line
          return updatedSeats;
        }

        // Check if the selection limit is reached
        if (
          prevSelectedSeats.filter((seat) => seat.row !== null).length >=
          onwardAdultCount
        ) {
          alert("Seat booking limit reached!");
          return prevSelectedSeats;
        }

        // Check if there is an available seat to select
        const nextAvailableSeatIndex = prevSelectedSeats.findIndex(
          (seat) => seat.row === null
        );

        if (nextAvailableSeatIndex !== -1) {
          const updatedSeats = [...prevSelectedSeats];
          updatedSeats[nextAvailableSeatIndex] = {
            row: column,
            seat: rowIndex + 1,
          };

          // Check if the selection limit is reached
          const isSelectionCompleteNow =
            updatedSeats.filter((seat) => seat.row !== null).length ===
            onwardAdultCount;
          setIsSelectionComplete(isSelectionCompleteNow);
          setExpandedPassengerIndex(nextAvailableSeatIndex); // Expand the passenger line
          return updatedSeats;
        }

        return prevSelectedSeats;
      });
    }

    // Rest of the code for updating column state
    const updatedColumnState = (prevState) => {
      const updatedColumn = [...prevState];
      updatedColumn[rowIndex] = updatedColumn[rowIndex] === 0 ? 1 : 0;
      return updatedColumn;
    };
    switch (column) {
      case "A":
        setColumnA(updatedColumnState);
        break;
      case "B":
        setColumnB(updatedColumnState);
        break;
      case "C":
        setColumnC(updatedColumnState);
        break;
      case "D":
        setColumnD(updatedColumnState);
        break;
      case "E":
        setColumnE(updatedColumnState);
        break;
      case "F":
        setColumnF(updatedColumnState);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // This code will run after the component re-renders
    console.log("Expanded Passenger Index:", expandedPassengerIndex);

    // You can add any logic that depends on the updated state here
  }, [expandedPassengerIndex]); // This ensures the effect runs only when expandedPassengerIndex changes

  useEffect(() => {
    // This code will run after the component re-renders
    console.log("Selected Seats:", selectedSeats);

    // You can add any logic that depends on the updated state here
  }, [selectedSeats]); // This ensures the effect runs only when selectedSeats changes

  // Seat Colur will change Function

  const getBoxColor = (value, column, rowIndex) => {
    const seatKey = `${column}-${String(rowIndex + 1).padStart(2, "0")}`;

    if (Prefilled.includes(seatKey)) {
      return "prefilled-color";
    }

    return value === 1 ? "filled-color" : "default-color";
  };

  // PAssnger Line will be expand the code
  console.log("Expanded Passenger Index:", expandedPassengerIndex);

  return (
    <>
      <div>
        <SliderImag />
      </div>
      <div className="SeatingArea_Continer">
        <div className="breadcum_stickys">
          <div className="Breadcum_Line_conf">
            <div className="Breadcum_Flow_conf">
              <Link to={"/"} className="link-without-underline">
                <div className="Breadcum_Flow_Home_conf">
                  <h1>M2M</h1>
                  <p> Home</p>
                </div>
              </Link>{" "}
              <div className="Breadcum_Flow_Logo_conf">
                <h1>&#62;</h1>
              </div>
              <div className="Breadcum_Flow_Departure_conf">
                <h1>Departure</h1>
                <p>Mumbai To Mandwa</p>
              </div>
              <div className="Breadcum_Flow_Logo_conf">
                <h1>&#62;</h1>
              </div>
              <div className="Breadcum_Flow_Departure_conf">
                <h1>Seating Area</h1>
                <p>Škoda Kushaq Zone</p>
              </div>
              <div className="Breadcum_Flow_Logo_conf">
                <h1>&#62;</h1>
              </div>
              <div className="Breadcum_Flow_Departure_conf">
                <h1>Return</h1>
                <p>Kashid To Mumbai</p>
              </div>
              <div className="Breadcum_Flow_Logo_conf">
                <h1>&#62;</h1>
              </div>
              <div className="Breadcum_Flow_Departure_conf">
                <h1>Seating Area</h1>
                <p>Škoda Kushaq Zone</p>
              </div>
            </div>
          </div>
        </div>

        <div className="SeatinArea_TwoDiv">
          <div className="SeatinArea_TwoDiv_div1">
            <div className="SeatinArea_TwoDiv_div1_heading">
              <div>
                <h1>Mumbai To Kashid</h1>
              </div>
              <div>
                <p>Škoda Kushaq Zone</p>
              </div>
            </div>
            <div className="SeatinArea_TwoDiv_div1_passanger">
              {Array.from({ length: onwardAdultCount }, (_, index) => (
                <div key={index} className="SeatinArea_TwoDiv_div1_passanger1">
                  <div className="SeatinArea_TwoDiv_div1_passanger1_Heading">
                    <h1>Passenger {index + 1} -</h1>
                    <p>
                      Seat <span>{selectedSeats[index].row}</span>
                      <span>{selectedSeats[index].seat}</span>
                    </p>
                  </div>
                  {console.log(
                    "Index:",
                    index,
                    "Expanded Passenger Index:",
                    expandedPassengerIndex
                  )}
                  {expandedPassengerIndex === index && (
                    <div className="SeatinArea_TwoDiv_div1_passanger1_ExpandedSEction">
                      {/* ... (rest of the expanded section JSX) */}
                      <div className="expanded_area_inputs">
                        <input placeholder="First Name" type="text" />
                        <input placeholder="Last Name" type="text" />
                      </div>
                      <div className="expanded_area_inputs_buttonDiv">
                        <button>Save</button>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          {/* 2nd div setaing seats start */}
          <div className="SeatinArea_TwoDiv_div2">
            <div className="Select_Seat_book_bySeats">
              <div className="headingNewSeats">
                <h1>Select Your Seat</h1>
              </div>
              <div className="SeatBooking_Container">
                <div className="grid_container_SeatBooking">
                  <div className="column_SeatBooking">
                    <h2>A</h2>
                    {columnA.map((box, index) => (
                      <div
                        key={index}
                        className={`box_SeatBooking ${getBoxColor(
                          box,
                          "A",
                          index
                        )}`}
                        onClick={() => handleBoxClick("A", index)}
                      ></div>
                    ))}
                  </div>

                  <div className="column_SeatBooking">
                    <h2>B</h2>
                    {columnB.map((box, index) => (
                      <div
                        key={index}
                        className={`box_SeatBooking ${getBoxColor(
                          box,
                          "B",
                          index
                        )}`}
                        onClick={() => handleBoxClick("B", index)}
                      ></div>
                    ))}
                  </div>

                  <div className="column_SeatBooking">
                    <h2>C</h2>
                    {columnC.map((box, index) => (
                      <div
                        key={index}
                        className={`box_SeatBooking ${getBoxColor(
                          box,
                          "C",
                          index
                        )}`}
                        onClick={() => handleBoxClick("C", index)}
                      ></div>
                    ))}
                  </div>
                </div>
                <div className="grid_container_SeatBooking_NUMBER">
                  <div className="column_number">
                    {column.map((number, index) => (
                      <div
                        key={index}
                        className="box"
                        // onClick={() => handleBoxClick("D", index)}
                      >
                        <p> {number}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="grid_container_SeatBooking1">
                  <div className="column_SeatBooking">
                    <h2>D</h2>
                    {columnD.map((box, index) => (
                      <div
                        key={index}
                        className={`box_SeatBooking ${getBoxColor(
                          box,
                          "D",
                          index
                        )}`}
                        onClick={() => handleBoxClick("D", index)}
                      ></div>
                    ))}
                  </div>

                  <div className="column_SeatBooking">
                    <h2>E</h2>
                    {columnE.map((box, index) => (
                      <div
                        key={index}
                        className={`box_SeatBooking ${getBoxColor(
                          box,
                          "E",
                          index
                        )}`}
                        onClick={() => handleBoxClick("E", index)}
                      ></div>
                    ))}
                  </div>

                  <div className="column_SeatBooking">
                    <h2>F</h2>
                    {columnF.map((box, index) => (
                      <div
                        key={index}
                        className={`box_SeatBooking ${getBoxColor(
                          box,
                          "F",
                          index
                        )}`}
                        onClick={() => handleBoxClick("F", index)}
                      ></div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <FotterAll />
      </div>
    </>
  );
};

export default SeatingArea;
