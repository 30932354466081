
import React, { useEffect, useState } from 'react';
import './Onward_M2M_Page.css';
import MainNavbar from '../../Components/Navbar/MainNavbar';
import FotterAll from '../../Components/Footer/FotterAll';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const Onboard_M2M_Princess = () => {
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [expandedIndexMob, setExpandedIndexMob] = useState(0);


  const images = [
    { heading: 'Seating Areas', image: '/assets/img/Gallery1/Seating_Areas/Skoda_Slavia_Deck/new_seat_area2.jpg', text: 'Choose from Škoda Kushaq Zone, Škoda Octavia Deck, and Škoda Superb Lounge, three distinct seating options offering unique features that elevate your comfort and fun.' },
    { heading: 'Dining', image: 'assets/img/Gallery1/Dining/Meemo_s_Cafe/new_dining1.jpg', text: 'Add more joy to your sails with delicious food and refreshing beverages at our onboard cafe and bar.' },
    { heading: 'Vehicle Deck', image: '/assets/img/Gallery1/Vehicle_Deck/new_vechicle_deck4.jpg', text: 'Our ferry offers a spacious vehicle deck, effortlessly accommodating over 125 cars, two-wheelers, and more for a seamless travel experience.' },
  ];

  const slideTexts = [
    {
      area: "Seating Area 1",
      description: "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.",
    },
    {
      area: "Seating Area 2",
      description: "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.",
    },
    {
      area: "Seating Area 3",
      description: "Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.",
    },
  ];

  const handleImageHover = (index) => {
    setExpandedIndex(index);
    setExpandedIndexMob(index === expandedIndexMob ? -1 : index);
  };

 // AutoMatic Scrolling Top
 useEffect(() => {
  // Scroll to the top when the component mounts
  window.scrollTo(0, 0);
}, []);
  return (
    <div>
      <div className="navbarContainer-sticky Main-navbar-border">
        <MainNavbar />
      </div>
      <div className="Onward_M2M_Section">
        <div className="Onward_M2M_Section_Heading">
          <h1>Onboard M2M-Princess</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.</p>{" "}
        </div>
        <div className="Onward_M2M_WhiteLinearGradint"></div>
      </div>

      {/* For Desktop Section start */}
      <div className='carousel-container-Desk'>
        <div className="carousel-container">
          {images.map((image, index) => (
            <div
              key={index}
              className="image-box"
              onMouseEnter={() => handleImageHover(index)}
              onMouseLeave={() => handleImageHover(0)}
              style={{ width: expandedIndex === index ? '50%' : '25%' }}
            >
              <div
                className="imageContent"
                style={{
                  width: '100%',
                  height: '633px',
                  objectFit: 'cover',
                  backgroundImage: `url(${image.image})`,
                  backgroundRepeat:'no-repeat'
                }}
              >
              <div className='imageContent_Main'>
                  <div className={expandedIndex === index ? 'imageContentDiv1 expanded' : 'imageContentDiv1'}>
                  <h2>{(index + 1).toString().padStart(2, '0')}</h2>
                  </div>
                  {expandedIndex === index && (
                    <div className='imageContentDiv2'>
                     <h1>{slideTexts[index].area}</h1>
                      <p>{slideTexts[index].description}</p>
                      <Link to={"/events"}>
                      <button className='GalleryButton'>Know More</button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* For Mobile Section start */}
        <div className="carousel-container-mob">
          {images.map((image, index) => (
            <div
              key={index}
              className="image-box"
              onClick={() => handleImageHover(index)}
              style={{
                width: '100%',
                height: expandedIndexMob === index ? '355px' : '100px',
                marginBottom: '10px',
                backgroundImage: `url(${image})`
              }}
            >
              <div className='imageContentDiv1'>
                <h2>{(index + 1).toString().padStart(2, '0')}</h2>
              </div>
              {expandedIndexMob === index && (
                <div className='imageContentDiv2'>
                  <h1>Seating Area</h1>
                  <p>Choose Between 3 Different Seating Options, Which All Offer Something Unique While Staying True To The M2M Experience Of Comfort And Fun.</p>
                  <Link to={"/events"}>
                    <button className='GalleryButton'>Know More</button>
                  </Link>
                </div>
              )}
            </div>
          ))}
        </div>
      <FotterAll />
    </div>
  );
};

export default Onboard_M2M_Princess;


