import React, { useEffect } from "react";
//import Calendar from "./BigCalender";
import FullCalendar from "./FullCalendar";
import FotterAll from "../../Components/Footer/FotterAll";
import "./Schedule.css";
import MainNavbar from "../../Components/Navbar/MainNavbar";
const Schedule = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="navbarContainer-sticky Main-navbar-border">
        <MainNavbar />
      </div>
      <div className="Schedule_Section">
        <div className="Schedule_Section_Heading">
          <h1>Schedule</h1>
          <p>Smooth sailing starts here. Explore the upcoming schedules to plan your getaway.</p>{" "}
        </div>
        <div className="Schedule_WhiteLinearGradint"></div>
      </div>
      <div className="SchedulePageMainContiner">
        <FullCalendar />
      </div>

      <FotterAll />
    </div>
  );
};

export default Schedule;
